import styled from "styled-components"

export const Section = styled.section`
  padding-top: 5rem;
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  /* ==== = MEDIUM = ==== */
  @media only screen and (min-width: 48em) {
    padding-top: 10rem;
  }
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
`

export const ParagraphWrapper = styled.p`
  margin-top: 2rem;
  max-width: 1200px;
`

import { createGlobalStyle } from "styled-components"
import "./fonts.css"

const GlobalStyles = createGlobalStyle`
  :root {
    --body: #1B1E22;
    --font-color: #e2e2e2;
    --mint: #AEC1B5;
    --footer: #181B1F;
    --font: "Neue Montreal";
    --headline: "Montserrat";
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

html {
    font-size: 62.5%;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: #FDFDFF;
    color: var(--font-color);
    font-family: var(--font);
    font-size: 2rem;
    line-height: 1.618;
    background-color: var(--body)
}

body.no-scroll {
    height: 100vh;
    overflow: hidden;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
    transition: all .3s ease-in;
    outline: none;
}



h1,
h2 {
    font-family: var(--headline);
    font-weight: 900;
    font-size: 4rem;
    text-transform: uppercase;
    color: var(--mint);
    line-height: 1;
}

h3 {
    font-family: var(--headline);
    font-weight: 900;
    color: white;
    font-size: 2.2rem;
    text-transform: lowercase;
    line-height: 1;
    margin-bottom: 2rem
}

p {
    text-transform: lowercase;
}

input {
    margin-right: 10px
}

audio {
    outline: none;
}

.genre {
    margin: 3.5rem 0 2rem 0;
}

/****** CUSTOMIZE ********/


/* TABS */

.tab-content {
    max-height: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    animation-name: slideUp;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    label {
        display: none;
    }
}

.labels {
    border-bottom: 1.5px solid var(--mint);
    padding-bottom: 5px;
    padding-top: 5rem;
}

input.tab {
  opacity: 0;
}

.labels > label > img {
  transition:all 0.3s ease-out;
    transform:rotate(0deg);
    cursor: pointer;
}

input.tab:checked {
  +.labels > label > img {
      transform: rotate(-90deg);
      transition-duration: 0.5s;
    }
  
  ~ .tab-content {
    display: flex;
    opacity: 1;
    max-height: 100%;
    flex-direction: column;
    animation-name: slideDown;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    label {
        display: block;
    }
  }
}

textarea {
  background: var(--body);
  color: var(--font-color);
  border: 1px solid var(--mint);
  font-family: inherit;
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
}

input[type="email"] {
  background: var(--body);
  color: var(--font-color);
  border: 1px solid var(--mint);
  font-family: inherit;
  font-size: 1.6rem;
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
}

@keyframes slideUp {
  0% {
   
    opacity: 0.1;
        height: 100%;
        padding: 20px 0;
  }


    100% {
    opacity: 0;
    height: 0;
    padding: 0;
    }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    height: 0;
    padding: 0;
  }
    100% {
        opacity: 1;
        height: 100%;
        padding: 20px 0;
    }
}


.price::before {
    content: "//";
    display: inline-block;
    margin-left: 20px;
    margin-right: 10px
}

.added-extras ul {
    padding: 0.3rem 0;
    margin-left: 20px;
}





/* Width in PX > 768px */
/* ==== = MEDIUM = ==== */

@media only screen and (min-width: 48em) {
    h1,
    h2 {
        font-size: 7rem;
    }
    h3 {
        font-size: 3.5rem;
    }
}

/* Width in PX > 1200px */
/* ==== = LARGE = ==== */
@media only screen and (min-width: 75em) {
    h1,
    h2 {
        font-size: 9rem;
    }

    /* GLITCH */
.glitch {
  position: relative;
  mix-blend-mode: lighten;

  &:before,
  &:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      width: 100%;
      background: black;
      clip: rect(0, 0, 0, 0);
  }
  
  &:before {
      left: -1px;
      text-shadow: 1.5px 0 yellow;
  }

  &:after {
      left: 1px;
      text-shadow: -1.5px 0 purple;
  }

  &:hover {
    &:before {
      text-shadow: 4px 0 rgba(red,0.7);
      animation: glitch-loop-1 0.8s infinite ease-in-out alternate-reverse;
    }
    &:after {
      text-shadow: -5px 0 rgba(blue,0.7);
      animation: glitch-loop-2 0.8s infinite ease-in-out alternate-reverse;
    }
  }
}

@keyframes glitch-loop-1 {
  0% { clip: rect(36px, 9999px, 9px, 0) }
  25% { clip: rect(25px, 9999px, 99px, 0) }
  50% { clip: rect(50px, 9999px, 102px, 0) }
  75% { clip: rect(30px, 9999px, 92px, 0) }
  100% { clip: rect(91px, 9999px, 98px, 0) }
}

@keyframes glitch-loop-2 {
  0% { top: -1px; left: 1px; clip: rect(65px, 9999px, 119px, 0) }
  25% { top: -6px; left: 4px; clip: rect(79px, 9999px, 19px, 0) }
  50% { top: -3px; left: 2px; clip: rect(68px, 9999px, 11px, 0) }
  75% { top: 0px; left: -4px; clip: rect(95px, 9999px, 53px, 0) }
  100% { top: -1px; left: -1px; clip: rect(31px, 9999px, 149px, 0) }
}

}
`

export default GlobalStyles

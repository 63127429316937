import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

import { Wrapper } from "../components/styles/Container"
import "./styles/klaus-gridski.css"
import "./styles/hamburger.css"
import Logo from "../img/icons/logo.svg"
import Facebook from "../img/icons/002-facebook.svg"
import Fiverr from "../img/icons/001-fiverr.svg"
import Mail from "../img/icons/paper-plane.svg"
import Insta from "../img/icons/instagram.svg"

const HeaderStyles = styled.header`
  padding: 3rem 0;
  ul {
    list-style: none;
    width: 30%;
    li {
      color: var(--font);
      font-size: 1.5rem;

      a {
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
  font-family: var(--headline);
  font-weight: 600;
`
const LogoStyle = styled.img`
  width: 17rem;
  height: 100%;
`

const MobileLogo = styled.img`
  width: 15rem;
`
const WrapperStyles = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn 1s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0%;
    }

    100% {
      opacity: 100%;
    }
  }
`

const Icon = styled.img`
  width: 20px;
  margin-left: 4rem;
`

const NavWrapper = styled.div`
  width: 100%;
`

const MobileMenu = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 8rem 5rem;
  background: var(--footer);
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  z-index: 1;

  ul {
    width: 100%;

    li {
      opacity: 0;
      padding: 2rem 0;
      font-size: 2rem;
      animation-name: fadeIn;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
    }
    li:first-child {
      animation-delay: 0.2s;
    }
    li:nth-child(2) {
      animation-delay: 0.3s;
    }
    li:nth-child(3) {
      animation-delay: 0.4s;
    }
    li:nth-child(4) {
      animation-delay: 0.5s;
    }
    li:last-child {
      animation-delay: 0.6s;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Header = () => {
  // Mediaqueries
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 75em)",
  })
  const isTablet = useMediaQuery({
    query: "(min-device-width: 48em) and (max-device-width: 75em)",
  })
  const isMobile = useMediaQuery({
    query: "(max-device-width: 48em)",
  })
  // // initialize state for mobile menu
  // const [showMenu, setshowMenu] = useState(false)

  // // function to change the state
  // const menuActive = () => {
  //   setshowMenu(!showMenu)
  //   const body = document.querySelector("body")
  //   body.classList.toggle("no-scroll")
  // }
  return (
    <HeaderStyles>
      <WrapperStyles>
        {isMobile || isTablet ? (
          <div>
            <Link to="/">
              <MobileLogo src={Logo} />
            </Link>
          </div>
        ) : (
          isDesktop && (
            <NavWrapper className="flex between v-center">
              <Link to="/">
                <LogoStyle src={Logo} />
              </Link>
              {/* <ul className="flex between">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/producing">Producing</Link>
                </li>
                <li>
                  <Link to="/mastering">Mastering</Link>
                </li>
              </ul> */}
              <div>
                <a
                  target="_blank"
                  title="send mail"
                  rel="noreferrer"
                  href="mailto:info@qualix-audio.com"
                >
                  <Icon src={Mail} />
                </a>

                <a
                  target="_blank"
                  rel="noreferrer"
                  title="qualix on facebook"
                  href="https://www.facebook.com/QualixAudio"
                >
                  <Icon src={Facebook} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="qualix on instagram"
                  href="https://www.instagram.com/qualix_audio/"
                >
                  <Icon src={Insta} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="qualix on fiverr"
                  href="https://www.fiverr.com/dolorian"
                >
                  <Icon src={Fiverr} />
                </a>
              </div>
            </NavWrapper>
          )
        )}
      </WrapperStyles>
    </HeaderStyles>
  )
}

export default Header
